import React, { useEffect, useState } from "react";
import { Typography, Card, Box, IconButton } from "@material-ui/core";
import InfoSharpIcon from "@material-ui/icons/InfoSharp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import axios from "axios";
import { useSelector } from "react-redux";

function NewsUpdate({ selectedCompanyName }) {
  const [news, setNews] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const myData = useSelector((state) => state?.ID?.Id || []);

  // Find the matching company by name
  const company = myData.find(function (company) {
    return company.name.toLowerCase() === selectedCompanyName.toLowerCase();
  });

  useEffect(() => {
    if (company) {
      const fetchNews = async () => {
        try {
          const res = await axios.get(
            `/competitor_intelligence/competitors/${company.id}/blogs`
          );
          setNews(res.data); 
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };

      fetchNews();
    }
  }, [company]);

  // Calculate the total pages
  const totalPages = news
    ? Math.ceil(news.result.urls.length / itemsPerPage)
    : 0;

  // Get the current news items
  const currentNewsItems = news
    ? news.result.urls.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    : [];

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Card style={{ margin: 20, padding: 20, backgroundColor: "#FFFFFF" }}>
      <Typography
        variant="h4"
        align="left"
        style={{
          marginLeft: 20,
          marginTop: 15,
          fontSize: 22,
          fontWeight: "bold",
        }}
      >
        News Update
      </Typography>
      <Box
        style={{
          backgroundColor: "#F7F6FA",
          padding: 20,
          borderRadius: 8,
          marginTop: 15,
        }}
      >
        {currentNewsItems.length > 0 ? (
          currentNewsItems.map((url, index) => (
            <div key={index} style={{ marginBottom: 20 }}>
              <Typography
                style={{
                  marginLeft: 20,
                  marginTop: 10,
                  fontSize: 20,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InfoSharpIcon style={{ marginLeft: 10 }} />
                Article {(currentPage - 1) * itemsPerPage + index + 1}
              </Typography>
              <Typography
                style={{ marginLeft: 20, marginTop: 10, fontSize: 18 }}
              >
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </Typography>
            </div>
          ))
        ) : (
          <div style={{ marginBottom: 20 }}>
            <Typography
              style={{
                marginLeft: 20,
                marginTop: 10,
                fontSize: 18,
                color: "#FF0000",
              }}
            >
              Check back soon for the latest updates on this news story.
            </Typography>
          </div>
        )}
        {news && news.result.urls.length > itemsPerPage && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              marginTop: 20,
              padding: "10px 20px",
              backgroundColor: "#EADDFF",
              borderRadius: 8,
              width: "fit-content",
              margin: "0 auto", // Center the pagination box
            }}
          >
            <IconButton
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Typography>
              {currentPage * itemsPerPage - itemsPerPage + 1}-
              {Math.min(currentPage * itemsPerPage, news.result.urls.length)} of{" "}
              {news.result.urls.length}
            </Typography>
            <IconButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default NewsUpdate;
