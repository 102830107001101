import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import CompetitorCard from "./card.jsx";
import CustomIconButton from "./IconButtonContainer.jsx";
import { useDispatch } from "react-redux";
import { setCompanyName } from "../Slices/CompanyNameSlice.js";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CardContainer = ({ title, companies }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const containerRef = useRef(null);

  const cardWidth = screenWidth >= 110 && screenWidth <= 740 ? 110 : 258;
  const cardHeight = screenWidth >= 110 && screenWidth <= 740 ? 110 : 220;
  const cardMargin = 23;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNextPage = () => {
    const container = containerRef.current;
    const maxScrollPosition = container.scrollWidth - container.clientWidth;

    setScrollPosition((prev) => {
      const newPosition = prev - (cardWidth + cardMargin);
      return Math.max(newPosition, -maxScrollPosition);
    });
  };

  const handlePreviousPage = () => {
    setScrollPosition((prev) => {
      const newPosition = prev + (cardWidth + cardMargin);
      return Math.min(newPosition, 0);
    });
  };

  const handleOnClick = (company) => {
    dispatch(setCompanyName([company]));
    navigate(`/competitor-details/${company.companyName}`);
  };

  return (
    <div>
      <Typography
        variant="h4"
        align="center"
        style={{
          marginTop: 52,
          marginLeft: screenWidth >= 110 && screenWidth <= 740 ? 0 : 36,
          fontSize: 34,
          fontWeight: "bold",
          marginBottom: 20,
          textAlign: "left",
          paddingLeft: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title && <span style={{ flex: 1 }}>{title}</span>}
        <span style={{ marginRight: 5 }}>
          {screenWidth >= 110 && screenWidth <= 740 ? (
            <>
              <CustomIconButton
                onClick={handlePreviousPage}
                icon={<ExpandLessIcon fontSize="large" />}
                marginRight={10}
              />
              <CustomIconButton
                onClick={handleNextPage}
                icon={<ExpandMoreIcon fontSize="large" />}
                marginRight={55}
              />
            </>
          ) : (
            <>
              <CustomIconButton
                onClick={handlePreviousPage}
                icon="<"
                marginRight={10}
              />
              <CustomIconButton
                onClick={handleNextPage}
                icon=">"
                marginRight={55}
              />
            </>
          )}
        </span>
      </Typography>
      <div
        ref={containerRef}
        id={title ? `${title.toLowerCase()}-cards-container` : "default-id"}
        style={{
          overflowX: screenWidth >= 110 && screenWidth <= 740 ? "auto" : "auto",
          overflowY: "auto",
          whiteSpace: "nowrap",
          padding: "20px",
          width: "100%",
          maxHeight:
            screenWidth >= 110 && screenWidth <= 740
              ? 3 * (cardHeight + cardMargin)
              : "none",
        }}
      >
        <div
          style={{
            width: companies.length * (cardWidth + cardMargin),
          }}
        >
          <Grid
            container
            spacing={2}
            direction={
              screenWidth >= 110 && screenWidth <= 740 ? "column" : "row"
            }
            style={{
              marginLeft: screenWidth >= 110 && screenWidth <= 740 ? 0 : 27,
              marginTop: 55,
              flexWrap: "nowrap",
              transform: `translate${
                screenWidth >= 110 && screenWidth <= 740 ? "Y" : "X"
              }(${scrollPosition}px)`,
            }}
          >
            {companies.map((company, i) => (
              <div
                key={i}
                onClick={() => handleOnClick(company)}
                style={{
                  display: "inline-block",
                  width: cardWidth,
                  marginBottom:
                    screenWidth >= 110 && screenWidth <= 740 ? 55 : 0,
                  marginRight:
                    screenWidth >= 110 && screenWidth <= 740 ? 0 : cardMargin,

                  verticalAlign: "top",
                  cursor: "pointer",
                }}
              >
                <CompetitorCard
                  companyLogo={company.companyLogo}
                  companyName={company.companyName}
                  index={i}
                />
              </div>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default CardContainer;
