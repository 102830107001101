import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Id: null,
};

const IDSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.Id = action.payload;
    },
  },
});

export const { setId } = IDSlice.actions;

export default IDSlice.reducer;