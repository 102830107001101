import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import InfoSharp from "@material-ui/icons/InfoSharp";
import NewsUpdate from "./NewsUpdate.jsx";
import CustomIconButton from "../Container/IconButtonContainer.jsx";
import schema from "../Product.json";
import schema1 from "../FinBoxProduct.json";
import schema2 from "../M2FinTechProduct.json";
import schema3 from "../DecimalProduct.json";
import schema4 from "../CyberLinkFaceMeProduct.json";
import schema5 from "../JocataProduct.json";
import schema6 from "../ViedeoCX.ioProduct.json";
import schema7 from "../JukshioProduct.json";
import schema8 from "../KarzaTechnologiesProduct.json";
import schema9 from "../SignzyProduct.json";
import schema10 from "../IDfyProduct.json";
import NIschema1 from "../TrueId.json";
import NIschema2 from "../VidaID.json";
import NIschema3 from "../TrustingSocialProduct.json";
import NIschema4 from "../Advance.Ai.json";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setId } from "../Slices/IDSlice.js";

const useStyles = makeStyles((theme) => ({
  card: {
    height: 683,
    width: 538,
    marginTop: 10,
    borderRadius: 8,
    border: "1px solid #ddd",
    overflow: "hidden",
    whiteSpace: "preWrap",
    wordWrap: "breakWord",
    overflowY: "scroll",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      height: 400,
      width: 300,
      marginRight: 10,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    overflowWrap: "break-word",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "normal", // Allow text to wrap to the next line
      textOverflow: "clip", // Remove ellipsis if text exceeds the limit
    },
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  list: {
    fontSize: 17,
    overflowX: "auto",
    whiteSpace: "normal",
    wordWrap: "breakWord",
    overflowY: "scroll",
  },
  warning: {
    fontSize: 17,
    marginLeft: 40,
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    marginRight: 5,
    color: "#0000FF",
    backgroundColor: "#fff",
    borderRadius: "50%",
  },
  warningIcon: {
    marginRight: 5,
    color: "#ff0000",
  },
}));

function ProductCard({
  productName,
  useCase = [],
  solutionOffering = [],
  customers = [],
  demo,
  features = [],
  category = [],
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" className={classes.title}>
          {productName}
        </Typography>

        <div style={{ marginTop: 16 }}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Use Case
          </Typography>
          {useCase.length > 0 ? (
            <ul className={classes.list}>
              {features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          ) : (
            <Typography variant="body1" className={classes.warning}>
              <WarningIcon className={classes.warningIcon} />
              Data cannot be retrieved
            </Typography>
          )}
        </div>
        <div style={{ marginTop: 16 }}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Solution Offering
          </Typography>
          {solutionOffering.length > 0 ? (
            <ul className={classes.list}>
              {category.map((categories, index) => (
                <li
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <InfoSharp className={classes.infoIcon} />
                  {categories}
                </li>
              ))}
            </ul>
          ) : (
            <Typography variant="body1" className={classes.warning}>
              <WarningIcon className={classes.warningIcon} />
              Data cannot be retrieved
            </Typography>
          )}
        </div>

        <div style={{ marginTop: 16 }}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Customers
          </Typography>
          {customers.length > 0 ? (
            <ul className={classes.list}>
              {customers.map((customer, index) => (
                <li key={index}>{customer}</li>
              ))}
            </ul>
          ) : (
            <Typography variant="body1" className={classes.warning}>
              <WarningIcon className={classes.warningIcon} />
              Data cannot be retrieved
            </Typography>
          )}
        </div>
        <div style={{ marginTop: 16 }}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Demo
          </Typography>
          {demo ? (
            <Typography variant="body1" className={classes.warning}>
              <InfoSharp className={classes.infoIcon} />
              <a
                href={demo}
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
              >
                View Demo
              </a>
            </Typography>
          ) : (
            <Typography variant="body1" className={classes.warning}>
              <WarningIcon className={classes.warningIcon} />
              Link is not available
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

function ProductFeaturesBreakdown({ selectedCompanyName }) {
  const containerRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(538);
  const [cardMargin, setCardMargin] = useState(20);
  let productDetails = schema;
  const { companyName } = useParams();

  useEffect(() => {
    // Update cardWidth and cardMargin based on screen size
    const handleResize = () => {
      if (window.innerWidth <= 740) {
        setCardWidth(300);
        setCardMargin(13);
      } else {
        setCardWidth(538);
        setCardMargin(20);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on mount to set initial sizes

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (selectedCompanyName === "Lentra") {
    productDetails = schema;
  } else if (selectedCompanyName === "FinBox") {
    productDetails = schema1;
  } else if (selectedCompanyName === "M2PFintech") {
    productDetails = schema2;
  } else if (selectedCompanyName === "Decimal") {
    productDetails = schema3;
  } else if (selectedCompanyName === "CyberLink-faceme") {
    productDetails = schema4;
  } else if (selectedCompanyName === "Jocata") {
    productDetails = schema5;
  } else if (selectedCompanyName === "VideoCX.io") {
    productDetails = schema6;
  } else if (selectedCompanyName === "Jukshio") {
    productDetails = schema7;
  } else if (selectedCompanyName === "Karza Technologies") {
    productDetails = schema8;
  } else if (selectedCompanyName === "Signzy") {
    productDetails = schema9;
  } else if (selectedCompanyName === "IDfy") {
    productDetails = schema10;
  } else if (selectedCompanyName === "TrueID") {
    productDetails = NIschema1;
  } else if (selectedCompanyName === "VidaID") {
    productDetails = NIschema2;
  } else if (selectedCompanyName === "Trusting Social") {
    productDetails = NIschema3;
  } else if (selectedCompanyName === "Advance.Ai") {
    productDetails = NIschema4;
  }

  const handleNextPage = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: cardWidth + cardMargin,
        behavior: "smooth",
      });
    }
  };

  const handlePreviousPage = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -(cardWidth + cardMargin),
        behavior: "smooth",
      });
    }
  };
  const dispatch = useDispatch();

  const fetch = async () => {
    try {
      const res = await axios.get(`/competitor_intelligence/competitors`);
      const competitors = res.data.result.competitors;

      // Extract all ids
      const ids = competitors.map((competitor) => competitor.id);
      const name = competitors.map((competitor) => competitor.name);
      console.log(name);
      dispatch(setId([...competitors]));

      console.log("All IDs:", ids);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  fetch();

  return (
    <div style={{ overflowY: "hidden" }}>
      <Typography
        variant="h4"
        align="left"
        style={{
          marginLeft: 40,
          marginTop: 17,
          fontSize: 26,
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Product / Features Break Down
        <span style={{ marginRight: 5 }}>
          <CustomIconButton
            onClick={handlePreviousPage}
            icon="&lt;"
            marginRight={10}
          />
          <CustomIconButton
            onClick={handleNextPage}
            icon="&gt;"
            marginRight={55}
          />
        </span>
      </Typography>
      <div
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          padding: "20px",
          maxHeight: 5000,
        }}
        ref={containerRef}
      >
        <Grid
          container
          spacing={2}
          style={{
            marginLeft: 27,
            marginTop: 18,
            flexWrap: "nowrap",
            display: "flex",
          }}
        >
          {productDetails[0].products.map((product, index) => (
            <Grid
              item
              key={index}
              style={{
                fontSize: 15,
                flex: "0 0 auto",
              }}
            >
              <ProductCard
                productName={product.name}
                useCase={product.category}
                solutionOffering={product.category}
                customers={product.customers}
                demo={product.demo}
                features={product.features}
                category={product.category}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <NewsUpdate selectedCompanyName={companyName} />
    </div>
  );
}

export default ProductFeaturesBreakdown;
