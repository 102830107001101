import React from "react";
import { IconButton } from "@material-ui/core";

const CustomIconButton = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      style={{
        fontSize: 34,
        fontWeight: "bold",
        marginRight: props.marginRight,
        color:"#000000"
      }}
    >
      {props.icon}
    </IconButton>
  );
};

export default CustomIconButton;

