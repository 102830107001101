import { configureStore } from "@reduxjs/toolkit";
import CardSliceReducer from "./Slices/CardSlice.js";
import DataSliceReducer from "./Slices/DataSlice.js";
import CompanyNameSliceReducer from "./Slices/CompanyNameSlice.js";
import IDSliceReducer from "./Slices/IDSlice.js"

const store = configureStore({
  reducer: {
    card: CardSliceReducer,
    data:DataSliceReducer,
    companyName:CompanyNameSliceReducer,
    ID:IDSliceReducer
  },
});

export default store;
