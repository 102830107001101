import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Component/Navbar.jsx';
import HomePage from './Pages/Homepage.jsx';
import CompetitorDetails from './Component/CompetitorDetails.jsx';
import LoginPage from './Pages/Loginpage.jsx';
import OTPVerification from './Component/OTPVerification.jsx';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleOtpVerification = () => {
    setIsAuthenticated(true);
  };

  return (
    <Router>
      {isAuthenticated ? (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/competitor-details/:companyName" element={<CompetitorDetails />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
          <Route path="/otp-verification" element={<OTPVerification onVerify={handleOtpVerification} />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;

