import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  IconButton,
} from "@material-ui/core";
import { Search, Home } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";


function Navbar() {
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(
    "(min-width: 740px) and (max-width: 1010px)"
  );
  const isSmallScreen = useMediaQuery("(max-width: 740px)");
  const mergedData = useSelector((state) => state.data.mergedData);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      const filteredCompanies = mergedData.filter((company) =>
        company.companyName.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredCompanies);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (companyName) => {
    setSearchQuery("");
    setSuggestions([]);
    setShowSearchBox(false); // Hide search box after selection
    navigateToCompanyDetails(companyName);
  };

  const navigateToCompanyDetails = (companyName) => {
    const companyExists = mergedData.some(
      (company) =>
        company?.companyName?.toLowerCase() === companyName.toLowerCase()
    );
    if (companyExists) {
      setSearchQuery("");
      setShowSearchBox(false); // Hide search box after search
      navigate(`/competitor-details/${companyName}`);
    } else {
      alert("Company name not found");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigateToCompanyDetails(searchQuery);
    }
  };

  const toggleSearchBox = () => {
    setShowSearchBox(!showSearchBox);
  };

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "#341F42", height: 125 }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
        }}
      >
        {!isSmallScreen && (
          <Typography
            variant="h6"
            style={{
              fontSize: 22,
              fontWeight: "bold",
              paddingLeft: 25,
              cursor: "none",
            }}
          >
            Competitor Intelligence - Tool
          </Typography>
        )}

        {isSmallScreen && (
          <img
            src="https://i.postimg.cc/wvm84w0S/ci.png"
            alt="CI"
            style={{ height: 40, paddingLeft: 25, cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        )}

        {!isSmallScreen && (
          <Box
            display="flex"
            justifyContent="center"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <InputBase
              placeholder={isMediumScreen ? "Search" : "Search competitor"}
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              endAdornment={
                <Search
                  style={{
                    paddingLeft: isMediumScreen ? 10 : 175,
                    paddingTop: 19,
                    paddingBottom: 19,
                    paddingRight: isMediumScreen ? 10 : 35,
                    color: "#333",
                  }}
                />
              }
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                paddingRight: isMediumScreen ? 10 : 25,
                paddingLeft: isMediumScreen ? 10 : 29,
                fontSize: 16,
                height: 55,
                width: isMediumScreen ? 205 : 420,
                borderRadius: 28,
                backgroundColor: "#fff",
              }}
            />
            {suggestions.length > 0 && (
              <Card
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                  marginTop: 10,
                  maxHeight: 200,
                  overflowY: "auto",
                }}
              >
                <CardContent>
                  <List>
                    {suggestions.map((company, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() =>
                          handleSelectSuggestion(company?.companyName)
                        }
                      >
                        {company?.companyName}
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            )}
          </Box>
        )}

        {isSmallScreen && (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            style={{ flex: 1, paddingRight: 25, position: "relative" }}
          >
            <IconButton onClick={toggleSearchBox}>
              <Search style={{ color: "#fff", cursor: "pointer" }} />
            </IconButton>
            <Home
              style={{ marginLeft: 20, color: "#fff", cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
            {showSearchBox && (
              <Box
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                  backgroundColor: "#fff",
                  padding: 10,
                  borderRadius: 8,
                  marginTop: 10,
                }}
              >
                <InputBase
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                  style={{
                    padding: 10,
                    fontSize: 16,
                    width: "100%",
                    borderRadius: 8,
                    backgroundColor: "#f0f0f0",
                  }}
                />
                {suggestions.length > 0 && (
                  <Card
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      zIndex: 1000,
                      marginTop: 5,
                      maxHeight: 200,
                      overflowY: "auto",
                    }}
                  >
                    <CardContent>
                      <List>
                        {suggestions.map((company, index) => (
                          <ListItem
                            button
                            key={index}
                            onClick={() =>
                              handleSelectSuggestion(company?.companyName)
                            }
                          >
                            {company?.companyName}
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                )}
              </Box>
            )}
          </Box>
        )}

        {!isSmallScreen && (
          <Typography
            variant="h6"
            style={{
              fontSize: 22,
              fontWeight: "bold",
              paddingRight: 25,
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            Home
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
