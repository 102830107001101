import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Typography, Grid, Box } from "@material-ui/core";
import OTPInput from "react-otp-input";

const OTPVerification = ({ onVerify }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const email = location.state?.email || "";
  const generatedOtp = location.state?.otp || "";

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleVerify = () => {
    if (otp === generatedOtp) {
      onVerify(); // Set authentication state
      navigate(`/`);
    } else {
      alert("Invalid OTP. Please try again.");
    }
  };

  return (
    <Grid container style={{ height: "100vh", position: "relative" }}>
      <Grid
        item
        xs={12}
        md={4}
        style={{ backgroundColor: "#003366", padding: 20 }}
      >
        <Typography variant="h3" style={{ color: "#FFFFFF" }}>
          CI-Tool
        </Typography>
        <Typography variant="h5" style={{ color: "#FFFFFF", marginTop: 20 }}>
          Sign In
        </Typography>
        <Typography variant="body1" style={{ color: "#FFFFFF", marginTop: 10 }}>
          Sign In is simple, free, and fast. One place to manage everyone and
          everything.
        </Typography>

        <Box
          style={{
            position: "absolute",
            bottom: 0, // Adjust the distance from the bottom as needed
            left: 10,
            right: 10,
            textAlign: "center",
          }}
        >
          <img
            src="https://i.ibb.co/3prsR3K/ci-tool-logo.png"
            alt="CI-Tool logo"
            style={{ width: "100%", height: "auto" }} // Adjust width and height accordingly
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={8} style={{ padding: 40 }}>
        <Typography variant="h4" gutterBottom>
          Welcome to CI-Tool
        </Typography>
        <Typography variant="h6" gutterBottom>
          Break Barriers, Set Trends: Intelligence That Inspires Innovation
        </Typography>

        <Typography variant="h5" style={{ marginTop: 40 }}>
          Check your inbox to confirm your email address
        </Typography>
        <Typography variant="body1" style={{ marginTop: 10 }}>
          We sent an OTP to {email}. If you don't see it, check your spam
          folder. After confirming your email, you can explore the platform.
        </Typography>

        <OTPInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span>-</span>}
          inputStyle={{
            width: "3rem",
            height: "3rem",
            margin: "0 1rem",
            fontSize: "2rem",
            borderRadius: 4,
            border: "1px solid rgba(0,0,0,0.3)",
          }}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: 30 }}
          onClick={handleVerify}
        >
          Verify and Continue
        </Button>

        <Box
          mt={5}
          style={{
            position: "absolute",
            bottom: 0,
            left: "20px",
            right: "20px",
            backgroundColor: "grey",
            textAlign: "center",
            padding: "10px",
            borderRadius: "20px 20px 20 0", // Curved top corners
          }}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            Private Policies and Terms of Conditions
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OTPVerification;
