import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const CompetitorCard = ({
  companyLogo,
  companyName,
  index,
  navigate,
  companyIds,
}) => {
  const mergedDetails = useSelector((state) => state.data.mergedData);
  console.log(mergedDetails, "data");
  // console.log(index);

  const screenWidth = window.innerWidth;

  return (
    <Grid
      item
      xs={2}
      sm={2}
      md={2}
      lg={2}
      style={{ margin: 5, flexBasis: 258 }}
    >
      <Card
        style={{
          height: screenWidth >= 110 && screenWidth <= 740 ? 125 : 220,
          width: screenWidth >= 110 && screenWidth <= 740 ? 510 : 258,
          border: "1px solid #ddd",
          borderRadius: 8,
          marginTop: -55,
          cursor: "pointer",
          boxShadow: "0 15px 10px rgba(0, 0, 0, 0.2)",
          "&:hover": { boxShadow: "0 4px 8px rgba(0,0,0,0)" },
          display: "flex",
          flexDirection:
            screenWidth >= 110 && screenWidth <= 740 ? "row" : "column",
        }}
      >
        <img
          src={companyLogo}
          alt=""
          style={{
            marginTop: "16px",
            marginLeft: "16px",
            width: screenWidth >= 110 && screenWidth <= 740 ? 110 : 225,
            height: screenWidth >= 110 && screenWidth <= 740 ? 80 : 135,
            alignItems:
              screenWidth >= 110 && screenWidth <= 740 ? "center" : "",
            justifyContent:
              screenWidth >= 110 && screenWidth <= 740
                ? "center"
                : "flex-start",
            borderRadius: 8,
            boxShadow: `
              -4px -4px 10px rgba(0, 0, 0, 0.2), 
              4px 4px 10px rgba(0, 0, 0, 0.2) 
            `,
          }}
        />
        <CardContent
          style={{
            padding: "8px",
            height: screenWidth >= 110 && screenWidth <= 740 ? 40 : "auto",
            marginLeft: screenWidth >= 110 && screenWidth <= 740 ? "50px" : "0", // Add margin between logo and name
            flex: 1,
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontSize: 19,
              paddingRight: 16,
              // marginLeft: 16,
              marginRight: screenWidth >= 110 && screenWidth <= 740 ? 3 : 0,
              marginLeft: screenWidth >= 110 && screenWidth <= 740 ? 3 : 0,
              alignItems:
                screenWidth >= 110 && screenWidth <= 740 ? "center" : "",
              marginTop: screenWidth >= 110 && screenWidth <= 740 ? 30 : 0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {companyName}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CompetitorCard;
