import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WarningIcon from "@material-ui/icons/Warning";
import ProductFeaturesBreakdown from "./ProductFeatures";
import schema from "../Product.json";
import schema1 from "../FinBoxProduct.json";
import schema2 from "../M2FinTechProduct.json";
import schema3 from "../DecimalProduct.json";
import schema4 from "../CyberLinkFaceMeProduct.json";
import schema5 from "../JocataProduct.json";
import schema6 from "../ViedeoCX.ioProduct.json";
import schema7 from "../JukshioProduct.json";
import schema8 from "../KarzaTechnologiesProduct.json";
import schema9 from "../SignzyProduct.json";
import schema10 from "../IDfyProduct.json";
import NIschema1 from "../TrueId.json";
import NIschema2 from "../VidaID.json";
import NIschema3 from "../TrustingSocialProduct.json";
import NIschema4 from "../Advance.Ai.json";

const CompetitorDetails = () => {
  const { companyName } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);
  const isMobile = useMediaQuery("(max-width: 740px)");

  useEffect(() => {
    console.log("companyName from params:", companyName);

    const schemas = [
      schema,
      schema1,
      schema2,
      schema3,
      schema4,
      schema5,
      schema6,
      schema7,
      schema8,
      schema9,
      schema10,
      NIschema1,
      NIschema2,
      NIschema3,
      NIschema4,
    ];

    const details = schemas.find((s) => s[0]?.companyName === companyName);
    console.log("Matching details:", details);

    setCompanyDetails(details ? details[0] : null);
  }, [companyName]);

  if (!companyDetails) {
    return <div>Loading...</div>;
  }

  const renderSection = (title, content, isList = false) => {
    const isContentArray = Array.isArray(content);
  
    return (
      <div style={{ marginTop: 36 }}>
        {isMobile ? (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${title}-content`}
              id={`${title}-header`}
            >
              <Typography variant="h5" style={{ fontSize: 18 }}>
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                maxHeight: 500,
                overflowY: "auto",
              }}
            >
              {isContentArray && isList ? (
                <ul>
                  {content.map((item, index) => (
                    <li key={index} style={{ marginBottom: "5px" }}>
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: 16,
                          marginLeft: 0,
                          marginTop: 16,
                          color: "#757575",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ) : content ? (
                <Typography
                  variant="body2"
                  style={{
                    fontSize: 16,
                    marginLeft: 0,
                    marginTop: 16,
                    color: "#757575",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {content}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  style={{
                    fontSize: 16,
                    marginLeft: 0,
                    marginTop: 16,
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <WarningIcon style={{ marginRight: 8 }} />
                  {`${title} data is not available.`}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ) : (
          <>
            <Typography variant="h5" style={{ fontSize: 22 }}>
              {title}
            </Typography>
            {isContentArray && isList ? (
              <ul>
                {content.map((item, index) => (
                  <li key={index} style={{ marginBottom: "5px" }}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 17,
                        marginLeft: 52,
                        marginTop: 16,
                        color: "#757575",
                      }}
                    >
                      {item}
                    </Typography>
                  </li>
                ))}
              </ul>
            ) : content ? (
              <Typography
                variant="body2"
                style={{
                  fontSize: 17,
                  marginLeft: 52,
                  marginTop: 16,
                  color: "#757575",
                }}
              >
                {content}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                style={{
                  fontSize: 17,
                  marginLeft: 52,
                  marginTop: 16,
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <WarningIcon style={{ marginRight: 8 }} />
                {`${title} data is not available.`}
              </Typography>
            )}
          </>
        )}
      </div>
    );
  };
  

  return (
    <div>
      <Typography
        variant="h4"
        align="left"
        style={{
          marginLeft: 40,
          marginTop: 17,
          fontSize: 32,
          fontWeight: "bold",
        }}
      >
        {companyDetails?.companyName}
      </Typography>
      <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
        <Grid item xs={12} sm={6}>
          <Card
            style={{
              marginLeft: isMobile ? 20 : 50,
              height: isMobile ? 500 : 945,
              width: isMobile ? 350 : 915,
              marginTop: 10,
              borderRadius: 8,
              border: "1px solid #ddd",
              overflowY: "auto",
            }}
          >
            <CardContent>
              {renderSection(
                "Description",
                companyDetails?.companyDetails?.description
              )}
              {renderSection(
                "Most Talked Things?",
                companyDetails?.companyDetails?.MostTalkedThings,
                true
              )}
              {renderSection(
                "Pricing Details",
                companyDetails?.companyDetails?.pricingDetails
              )}
              {renderSection(
                "All Things About",
                companyDetails?.companyDetails?.["All Things About"]
              )}
            </CardContent>
          </Card>
        </Grid>
        {!isMobile && (
          <Grid item xs={6}>
            <Card
              style={{
                marginLeft: 290,
                height: 945,
                width: 410,
                marginTop: 10,
                borderRadius: 28,
                border: "1px solid #ddd",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  style={{ marginBottom: 5, fontSize: 23, fontWeight: "bold" }}
                >
                  All Client List
                </Typography>
                <div
                  style={{
                    overflowY: "auto",
                    maxHeight: 800,
                    overflowX: "hidden",
                  }}
                >
                  {companyDetails?.["All Client List"]?.map((client, index) => (
                    <div key={index} style={{ marginBottom: 12 }}>
                      <Typography
                        variant="body1"
                        style={{ fontSize: 16, marginTop: 25 }}
                      >
                        <span
                          style={{
                            backgroundColor: "#EADDFF",
                            padding: "10px 15px",
                            borderRadius: "50%",
                            marginRight: "15px",
                            color: "#000000",
                            fontWeight: "bold",
                          }}
                        >
                          {client.substring(0, 1)}
                        </span>
                        {client}
                      </Typography>
                      <hr
                        style={{
                          border: "1px solid #ddd",
                          marginTop: 25,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Card
              style={{
                marginLeft: 20,
                height: 945,
                width: 350,
                marginTop: 10,
                borderRadius: 28,
                border: "1px solid #ddd",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  style={{ marginBottom: 5, fontSize: 23, fontWeight: "bold" }}
                >
                  All Client List
                </Typography>
                <div
                  style={{
                    overflowY: "auto",
                    maxHeight: 800,
                    overflowX: "hidden",
                  }}
                >
                  {companyDetails?.["All Client List"]?.map((client, index) => (
                    <div key={index} style={{ marginBottom: 12 }}>
                      <Typography
                        variant="body1"
                        style={{ fontSize: 16, marginTop: 25 }}
                      >
                        <span
                          style={{
                            backgroundColor: "#EADDFF",
                            padding: "10px 15px",
                            borderRadius: "50%",
                            marginRight: "15px",
                            color: "#000000",
                            fontWeight: "bold",
                          }}
                        >
                          {client.substring(0, 1)}
                        </span>
                        {client}
                      </Typography>
                      <hr
                        style={{
                          border: "1px solid #ddd",
                          marginTop: 25,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <ProductFeaturesBreakdown selectedCompanyName={companyName} />
    </div>
  );
};

export default CompetitorDetails;
