import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyName: [],
};

const CompanyNameSlice = createSlice({
  name: "companyName",
  initialState,
  reducers: {
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    //   console.log(action.payload)
    },
  },
});

export const {setCompanyName} = CompanyNameSlice.actions;

export default CompanyNameSlice.reducer;