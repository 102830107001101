import React, { useState } from "react";
import { Typography, TextField, Button, Grid, Box } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import OTPInput from "react-otp-input";

function LoginPage({ onLogin }) {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [error, setError] = useState(""); // State for error message
  const navigate = useNavigate();

  const handleSendOtp = () => {
    // Check if the email ends with @hyperverge.co
    if (!email.endsWith("@hyperverge.co")) {
      setError("@hyperverge.co is compulsory");
      return;
    }

    const generatedOtp = Math.floor(1000 + Math.random() * 9000).toString();
    setOtp(generatedOtp);

    const templateParams = {
      email: email,
      otp: generatedOtp,
    };
    console.log("Sending OTP to:", email);

    emailjs
      .send(
        "service_8s6r1xl",
        "template_ut03owe",
        templateParams,
        "aAPA-pJ6a3uWnKPgP"
      )
      .then(
        (response) => {
          console.log("OTP sent successfully!", response.status, response.text);
          setIsOtpSent(true); // Update the state to show OTP input
          setError(""); // Clear error message on successful OTP send
        },
        (error) => {
          console.error("Failed to send OTP.", error);
        }
      );
  };

  const handleOtpVerification = (otp) => {
    setEnteredOtp(otp);
  };

  const handleSubmit = () => {
    if (enteredOtp === otp) {
      onLogin();
      navigate("/");
    } else {
      alert("Invalid OTP. Please try again.");
      setEnteredOtp(""); // Clear the OTP input
    }
  };

  return (
    <Grid container style={{ height: "100vh", position: "relative" }}>
      <Grid
        item
        xs={12}
        md={4}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* First Half - CI-Tool Background Details */}
        <Box
          style={{
            flex: 1,
            backgroundColor: "#0000cd",
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" style={{ color: "#fff", marginBottom: 20 }}>
            CI-Tool
          </Typography>
          <Typography variant="h6" style={{ color: "#fff", marginBottom: 10 }}>
            Sign In
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#fff", marginBottom: "auto" }}
          >
            Sign In is simple, free, and fast. One place to manage everyone and
            everything.
          </Typography>
        </Box>

        {/* Second Half - Image */}
        <Box
          style={{
            flex: 1,
            backgroundColor: "#fff",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="https://t4.ftcdn.net/jpg/01/19/11/55/360_F_119115529_mEnw3lGpLdlDkfLgRcVSbFRuVl6sMDty.jpg"
            alt="CI-Tool logo"
            style={{ width: "100%", height: "385px", top: 0 }}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={8}
        style={{ padding: 20, position: "relative", paddingBottom: "60px" }}
      >
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Welcome to CI-Tool
        </Typography>
        <Typography variant="h6" style={{ marginBottom: 20 }}>
          Break Barriers, Set Trends: Intelligence That Inspires Innovation
        </Typography>

        <TextField
          fullWidth
          label="Company Email"
          variant="outlined"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(""); // Clear error message when email is updated
          }}
          style={{ marginBottom: 10 }}
        />
        <Typography variant="body2" style={{ marginBottom: 20 }}>
          Please enter the company email id. For example: abc123@hyperverge.co
        </Typography>

        {error && (
          <Typography
            variant="body2"
            style={{ color: "red", marginBottom: 10 }}
          >
            {error}
          </Typography>
        )}

        {isOtpSent ? (
          <>
            <OTPInput
              value={enteredOtp}
              onChange={handleOtpVerification}
              numInputs={4}
              separator={<span>-</span>}
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 1rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid rgba(0,0,0,0.3)",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              style={{ marginTop: 20 }}
            >
              Verify and Submit
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSendOtp}
            disabled={!email}
          >
            Send OTP
          </Button>
        )}

        <Box
          mt={5}
          style={{
            position: "absolute",
            bottom: 0,
            left: "0px",
            right: "0px",
            backgroundColor: "grey",
            textAlign: "center",
            padding: "10px",
          }}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            Private Policies and Terms of Conditions
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LoginPage;


