import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyId: null,
};

const CardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
  },
});

export const { setCompanyId } = CardSlice.actions;

export default CardSlice.reducer;
