import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mergedData: [],
};

const DataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setMergedData: (state, action) => {
      state.mergedData = action.payload;
      console.log(action.payload)
    },
  },
});

export const {setMergedData} = DataSlice.actions;

export default DataSlice.reducer;
