import React from "react";
import CardContainer from "../Container/CardContainer.jsx";
import schema from "../Product.json";
import schema1 from "../FinBoxProduct.json";
import schema2 from "../M2FinTechProduct.json";
import schema3 from "../DecimalProduct.json";
import schema4 from "../CyberLinkFaceMeProduct.json";
import schema5 from "../JocataProduct.json";
import schema6 from "../ViedeoCX.ioProduct.json";
import schema7 from "../JukshioProduct.json";
import schema8 from "../KarzaTechnologiesProduct.json"
import schema9 from "../SignzyProduct.json"
import schema10 from "../IDfyProduct.json"
import NIschema1 from "../TrueId.json";
import NIschema2 from "../VidaID.json";
import NIschema3 from "../TrustingSocialProduct.json";
import NIschema4 from "../Advance.Ai.json";
import NIschema5 from "../sampleCard.json";
import { useDispatch } from "react-redux";
import { setCompanyId } from "../Slices/CardSlice.js";
import { setMergedData } from "../Slices/DataSlice.js";

function HomePage() {
  const companyData = schema;
  const companyData1 = schema1;
  const companyData2 = schema2;
  const companyData3 = schema3;
  const companyData4 = schema4;
  const companyData5 = schema5;
  const companyData6 = schema6;
  const companyData7 = schema7;
  const companyData8 = schema8;
  const companyData9 = schema9;
  const companyData10 = schema10;
  const nonIndianCompanyData = NIschema1;
  const nonIndianCompanyData1 = NIschema2;
  const nonIndianCompanyData2 = NIschema3;
  const nonIndianCompanyData3 = NIschema4;
  const nonIndianCompanyData4 = NIschema5;
  const dispatch = useDispatch();

  const mergedData = [
    ...companyData8,
    ...companyData9,
    ...companyData10,
    ...companyData,
    ...companyData1,
    ...companyData2,
    ...companyData3,
    ...companyData4,
    ...companyData5,
    ...companyData6,
    ...companyData7,
    ...nonIndianCompanyData,
    ...nonIndianCompanyData1,
    ...nonIndianCompanyData2,
    ...nonIndianCompanyData3,
    ...nonIndianCompanyData4,
  ];
  dispatch(setMergedData([...mergedData]));
  const companyIds = mergedData.map((company) => company.id);
  dispatch(setCompanyId({ ...companyIds }));

  const indianCompanies = mergedData.filter(
    (company) => company.companyNationality === "Indian"
  );
  const nonIndianCompanies = mergedData.filter(
    (company) => company.companyNationality !== "Indian"
  );

  return (
    <div>
      <CardContainer title="Indian Competitor" companies={indianCompanies} />
      <CardContainer
        title="Non-Indian Competitor"
        companies={nonIndianCompanies}
      />
    </div>
  );
}

export default HomePage;
